<template>
  <div class="tw-w-full">
    <loader :loading="loading" v-if="loading"/>

  <div class="step-two" v-else>
      <landing-page-nav-bar/>

    <booking-steps />
    <div class="wrapper">
      <div class="back " @click="routeBack"><v-icon>mdi-chevron-left</v-icon>Back</div>
    </div>

    <pti /><div class="">

    <div class="selectseat ">
      <div class="tw-w-full">
        <seat-timer :tripId="userSelectedTrip.id"/>
      </div>

      <vehicle-seats :trip="userSelectedTrip" />
    </div>
    </div>
    <checkout-details />
    <Footer/>
  </div>
  </div>
</template>

<script>
  import BookingSteps from '@/components/reuseables/booking/OneWayBookingSteps.vue'
  import VehicleSeats from '@/components/transportCompany/booking/VehicleSeats.vue'
  import CheckoutDetails from '@/components/reuseables/booking/CheckoutDetails.vue'
  import pti from '@/components/reuseables/booking/UserSelectedTripInfo.vue'
  import {mapGetters} from "vuex";
  import Loader from "@/components/reuseables/Loader";
  // import {getOneTripOfTransportCompanySubscribedTo} from "@/services/api/APIService";
  import SeatTimer from "@/components/reuseables/booking/SeatTimer";
  import  defaultLogo from "@/assets/bluelogo.png"
  import {getTripByTransportCompanyIdAndTripId} from "@/services/api/APIService";
  import LandingPageNavBar from "@/components/reuseables/LandingPageNavBar.vue";
  import Footer from "@/components/reuseables/Footer.vue";
  export default {
    name : "OneWaySelectSeats",
    components: {
        Footer,
        LandingPageNavBar,
      SeatTimer,
      Loader,
      pti,
      CheckoutDetails,
      BookingSteps,
      VehicleSeats,

    },
    data(){
      return{
          selectedTrip: [],
          selectedTripBtn: false,
        loading: false,
        defaultLogo
      }
      },
    computed:{
      ...mapGetters("companyProfile", ["companyData"]),
      ...mapGetters("tripAndTravellerDetails", ["userSelectedTrip"])
    },
      methods:{
        routeBack(){
            let queryData = {}
            queryData.departure = this.userSelectedTrip.routeDepartureCity.toLowerCase()
            queryData.destination = this.userSelectedTrip.routeDestinationCity.toLowerCase()
            sessionStorage.setItem("queryData",JSON.stringify(queryData))
            this.$router.push({name: 'OneWayTrips'})
        },
      },
    created() {
      this.loading = true
        let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
        let passengerDetail = JSON.parse(sessionStorage.getItem("passengerDetail"))
        let userSelectedCategory = JSON.parse(sessionStorage.getItem("userSelectedCategory"))
        if (Object.keys(userSelectedTrip).length){
          let data = {}
          data.transportCompanyId = userSelectedTrip.transportCompanyId
          data.tripId = userSelectedTrip.id
          getTripByTransportCompanyIdAndTripId(data).then(res =>{
              if (res.data.tripRegion === 'crossCountry' && Object.keys(userSelectedCategory).length){
                 res.data.tripAmount = userSelectedCategory.tripAmount
              }
            this.$store.dispatch("tripAndTravellerDetails/setUserSelectedTrip",res.data)
            this.$store.dispatch("seatsSelection/getTotalAmount",res.data.tripAmount)
            this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails",passengerDetail)
            this.loading = false
          }).catch(()=>this.loading = false)
      }
    }
    
  }
</script>

<style lang='scss' scoped >
 .selectseat{
    width: 70%;
   padding-bottom: 2rem;

   @media screen and (max-width: 768px){
     width: 90%;
   }
  }

 .back{
   display: flex;
   align-items: center;
   font-family: 'Inter',sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 17px;
   color: #4F4F4F;
   width: max-content;
   text-transform: none;
   cursor: pointer;
 }

 .wrapper{
   width: 100%;
   padding: 0 10rem;

   @media screen and (max-width: 1024px) {
     padding: 1rem;
   }
 }

 .company-logo{
   width: auto;
   height: 40px;
 }
</style>